.uploads {
  &__instructions {
    border-radius: 10px;
    padding: 3.2rem;

    h3 {
      font-weight: 700;
    }
  }

  &__row {
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 1.6rem;
  }

  &__column {
    &:first-child {
      width: 25rem;
    }

    > button {
      margin: 0;
      padding: 0.8rem 3.2rem;

      &:first-child {
        margin-right: 3.2rem;
      }
    }
  }

  &__icon {
    background-color: #f2f2f2;
    border-radius: 10px;
    height: 4.8rem;
    margin-right: 1.6rem;
    width: 4.8rem;
  }

  .rows-container {
    min-height: 25rem;
  }
}
