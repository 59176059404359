.login-page-secondary {
  min-height: 100vh;

  &::before {
    background-image: url('../../assets/images/login-bg.png');
    background-size: cover;
  }

  .login-box {
    padding-bottom: 0;
    padding-top: 0;

    .login-header {
      margin-bottom: 4rem;

      h1 {
        color: inherit;
        font-size: 3.2rem;
      }
    }

    .login-content .custom-alert {
      &::before {
        top: 1.8rem;
      }
    }
  }

  .login-footer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;

    .logo-footer {
      &.visma-logo {
        background-image: url('../../assets/images/visma-logo-color.png');
      }

      &.uc-logo {
        background-image: url('../../assets/images/uc-group-logo-color.png');
        height: 30px;
      }
    }
  }

  .login-footer-alert {
    margin-top: 0;

    .ot-sdk-show-settings {
      background: inherit;
      border: none;
      color: var(--anchor-color);
      cursor: pointer;
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
    }
  }
}

input[type='text'].password-toggle {
  background-image: var(--password-field-icon);
  background-position: top 1.5rem right 1.6rem;
  background-repeat: no-repeat;
  padding-right: 4.8rem;
}

input[type='text'],
input[type='password'] {
  &.form-control::placeholder {
    color: #777;
    font-style: normal;
  }
}

.password-toggle-container {
  position: relative;

  .password-toggler {
    background: none;
    border: none;
    cursor: pointer;
    height: 4.8rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 4.8rem;
  }
}
