.upload-block {
  &__input-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &__input {
    opacity: 0;
    width: 100%;
  }

  &__label {
    position: relative;
    width: 13.6rem;
    z-index: 5;
  }

  &__file-custom {
    background-color: #fff;
    border: 1px solid #8a8a8a;
    color: #555;
    height: 3.2rem;
    left: 0;
    overflow: hidden;
    padding: 0.5rem 1rem;
    position: absolute;
    right: auto;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 28.2rem;
    z-index: 5;
  }

  &__info-msg {
    background: no-repeat;
    border: none;
    box-shadow: none;
    color: inherit;
    font-weight: 400;
    margin-top: -1rem;
    padding-left: 3rem;
    padding-right: 1.6rem;

    &::before {
      left: 0.4rem;
    }
  }

  &__btn {
    margin: 0;
    position: absolute;
    right: 0;

    &--disabled {
      border: 1px solid var(--button-disabled-text-color);
      color: var(--button-disabled-text-color);
    }
  }
}

.divider {
  align-items: center;
  color: #c0c0c0;
  display: flex;

  &__border {
    border-bottom: 1px solid #c0c0c0;
    flex: 1;
    width: 100%;
  }

  &__content {
    flex: 3;
    padding: 0 1rem;
    text-align: center;
  }
}

.drag-block {
  background-color: #f7f7f7;
  border-radius: 3.2rem;

  .drop-area {
    padding: 7.4rem 2.4rem 2.4rem;

    .drop-area-upload:not(.btn) {
      align-items: center;
      display: flex;
      justify-content: center;

      &::after {
        background-color: #252626;
        height: auto;
        left: auto;
        mask-image: url('./../../assets/images/upload-icon.svg');
        top: auto;
        width: auto;
      }
    }
  }
}

.section-title {
  font-weight: 700;
}

.upload {
  &__date {
    width: 23.3rem;
  }

  &__datepicker {
    display: flex;

    input {
      width: 100%;
    }

    .form-group:first-child {
      margin-right: 0.9rem;
    }
  }

  &__type {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

@media (max-height: 768px) {

  .drag-block,
  .drag-block~.divider {
    display: none;
  }
}
