.iframe-wrapper {
  flex-grow: 1;
  background: #fff;
  box-shadow: var(--shadow-30);
  border-radius: 1rem;
  padding: 0px 15px;

  iframe {
    width: 100%;
    border-radius: 1rem;
  }
}
