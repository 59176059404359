.overview {
  &__greeting {
    font-size: 3rem;
    line-height: 3rem;
  }

  &__name {
    display: flex;
    font-weight: bold;
  }

  &__card1 {
    background-image: url('../../assets/images/card-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 36rem;
  }

  &__card2 {
    align-items: center;
    background: linear-gradient(212.11deg, #afd4af -22.36%, #ebf7eb 78.65%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    > button {
      height: 4.8rem;
      margin-top: 3.2rem;
      padding: 1.6rem 3.2rem;
    }
  }

  &__card3 {
    .light-button {
      align-items: center;
      background-color: #f3f4f5;
      border: none;
      display: flex;
      height: 4.8rem;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 1.4rem;
      width: 100%;

      .caret-bg {
        align-items: center;
        background-color: #0087e0;
        border-radius: 10px;
        display: flex;
        height: 3.2rem;
        justify-content: center;
        width: 3.2rem;
      }

      .caret {
        background-color: var(--button-primary-text-color);
        display: inline-block;
        transform: rotate(270deg);
      }
    }
  }

  &__card4 {
    > button {
      max-width: 30rem;
    }
  }
}
