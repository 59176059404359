.download {
  &__client-code,
  &__date {
    width: 23.3rem;
  }

  &__datepicker {
    display: flex;

    input {
      width: 100%;
    }

    .form-group:first-child {
      margin-right: 0.9rem;
    }
  }
}
