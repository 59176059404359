.navbar.navbar-primary.header {
  .navbar-brand {
    .logo {
      min-width: 25rem;

      &:focus {
        outline: none;
      }
    }
  }

  .nav-item {
    font-size: 1.4rem;
  }
}
