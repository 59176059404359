.container {
  margin-bottom: 15rem;
  padding-top: 12rem;
  flex-grow: 1;

  h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
