.dropdown.user-dropdown {
  .dropdown-toggle.button-context {
    background-color: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    color: var(--navigation-default-item-text-color);
    font-size: 1.6rem;

    &:focus {
      outline: none;
    }

    .caret-bg {
      align-items: center;
      border-radius: 10px;
      display: flex;
      height: 4rem;
      justify-content: center;
      position: absolute;
      right: 1.6rem;
      width: 4rem;
    }

    &:hover {
      background-color: transparent;
      border-left: 1px solid rgb(255 255 255 / 30%);

      .caret-bg {
        background: #d2eafa;

        .caret {
          background-color: #1482cc;
        }
      }
    }
  }

  .dropdown-menu {
    border-top: 1px solid #dadada;

    &::before {
      width: 0;
    }

    li {
      line-height: 1.8rem;

      .btn-as-menu-item {
        background: no-repeat;
        border: none;
        padding: 0.8rem 3.2rem;
        text-align: left;
      }
    }

    .log-out-link {
      padding-right: 1rem;
      width: 100%;
    }

    .divider {
      padding-bottom: 1.3rem;
      padding-top: 1.3rem;
    }
  }

  &.open {
    .dropdown-toggle {
      background-color: #fff;
      color: var(--navigation-default-item-dropdown-open-text-color);

      &:hover {
        background-color: #fff;
      }
    }
  }
}
