.reports {
  margin-bottom: 0;

  &__block {
    height: 73vh;
  }

  &__embed {
    height: 100%;
  }
}
