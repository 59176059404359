/* stylelint-disable */
$datepicker__background-color: #f2f2f2 !default;
$datepicker__border-color: #8a8a8a !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #2d7048 !default;
$datepicker__text-color: #333 !default;
$datepicker__header-color: #333 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;
$datepicker__border-radius: 1.1rem !default;
$datepicker__day-margin: 0.3rem !default;
$datepicker__font-size: 1.3rem !default;
$datepicker__font-family: inherit;
$datepicker__item-size: 2.9rem !default;
$datepicker__margin: 1.6rem !default;
$datepicker__navigation-button-size: 38px !default;
$datepicker__triangle-size: 14px !default;
/* stylelint-enable */