.modal {
  h1,
  h2 {
    line-height: 3rem;

    span {
      display: flex;
    }
  }

  .sub-title {
    font-weight: 600;
  }

  .modal-body {
    overflow-y: visible;

    .left-side {
      width: 60%;
    }
  }

  .checkbox:focus-within {
    outline: none;
  }
}
