.footer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .logo {
    background-repeat: no-repeat;
    background-size: contain;
    height: 2.5rem;
    margin: 0 2rem;
    width: 9rem;
  }

  .visma-logo {
    background-image: url('../../assets/images/visma-logo.svg');
  }

  .uc-logo {
    background-image: url('../../assets/images/uc-group-logo-grey.png');
  }
}
