.language-switcher {
  display: flex;

  .flag {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 0 0 1.6rem;
    opacity: 0.5;
    padding: 0;

    &.active {
      opacity: 1;
    }

    img {
      height: 1.8rem;
      width: auto;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__title {
    min-width: 6.5rem;
  }

  &__buttons {
    display: flex;
    margin: auto;
  }
}
