// === override $prefix-path variable to make working vud in react
$prefix-path: '~@vismaux/vud/dist';
$image-path: '#{$prefix-path}/img';
$font-path: '#{$prefix-path}/fonts';
$icons-path: '#{$prefix-path}/img/vismaicons';
$tabs-graph-path: $image-path + '/tabs';

@import '~@vismaux/vud/src/scss/vud.light-dark.mode';
@import './assets/styles/styles.scss';

body {
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

iframe {
  border: none;
}

#ot-sdk-btn {
  display: none;
}
